<template>
  <div class="zch-privacy">
    <Navigation />
    <div class="zch-privacy-container">
      <div class="zch-privacy-heading">
        <img alt="ZAKchat" src="../assets/logo.svg" />
        <div class="zch-privacy-heading-effective-date">
          EFFECTIVE DATE: April 20th, 2020
        </div>
        <div class="zch-privacy-heading-notice">ZAKchat Privacy Policy</div>
      </div>
      <div class="zch-privacy-regulations">
        <div class="zch-privacy-regulations-item-text">
          ZAKchat are committed to keeping your information secure and managing
          it in accordance with our legal responsibilities under privacy and
          data protection laws where we operate. This Privacy Policy provides
          information about how we process personal information through
          ZAKchat.com.<br /><br />

          By visiting our website or using any of our services, you agree to
          your personal information being used and disclosed in the manner set
          out in this Policy. We will not collect personal information from you
          unless you have specifically agreed to us doing so (for example
          through your acceptance of this Policy or via an electronic tick-box
          or other similar measures as required by local privacy and data
          protection laws).<br /><br />

          You do not have to provide your personal information to us, but if you
          do not provide certain information we will only be able to provide
          limited services to you.
          <div class="zch-privacy-regulations-item-heading">
            The personal information that we collect
          </div>
          <div class="zch-privacy-regulations-item-text">
            When you use ZAKchat.com, as is the case with most websites, certain
            limited information (such as your browser type and IP address) will
            be collected by us automatically. We will also will collect and
            store certain personal and private information as follows:
          </div>
          <div class="zch-privacy-regulations-item-heading">
            Creating a ZAKchat account
          </div>
          <div class="zch-privacy-regulations-item-text">
            We will collect basic contact information about you to set up your
            account, so you can make donations. This will include your name,
            address, mobile number and email address. We may also ask you to
            register a username and password so you can gain secure access to
            your account in the future.<br /><br />

            Alternatively you may authorise us to collect your basic personal
            details from a secure online source (e.g. Google, Facebook, PayPal
            or a sponsor charity) to which you have already provided this
            information.<br /><br />

            If you choose to login via Facebook we will obtain access to your
            public profile information and email address. We will use this
            information to allow you to login and populate our records about
            you. If you give us permission (via Facebook’s preference settings)
            we may also obtain access to your friend list; we will only use this
            to see if people on your friends list are also using our website and
            as set out in How we use personal information below.
          </div>
          <div class="zch-privacy-regulations-item-heading">
            Making a donation through ZAKchat.com
          </div>
          <div class="zch-privacy-regulations-item-text">
            To enable us to process donations we will collect basic payment
            information. If you are eligible for UK Gift Aid, we may also ask
            you to confirm your UK home address.
          </div>
        </div>
        <div class="zch-privacy-regulations-item-heading">
          How we use personal information
        </div>
        <div class="zch-privacy-regulations-item-text">
          We use the personal information collected from users for a number of
          purposes:<br /><br />

          1. administering donations made (which may include Gift Aid, if
          applicable);<br /><br />

          2. personalising the look and feel of ZAKchat.com to fit personal
          preferences which you have told us about or which we have inferred
          from your usage of our website (such as donation history, and
          fundraising or charity page cares/likes, both on our website and,
          where you have given us permission, via social networks (see also the
          Cookies sections for more information)) or derived from market
          research;<br /><br />

          3. providing users with fundraising related events and news stories,
          tips and competitions, updates on campaigns, fundraising pages
          supported, comments on website content, details of similar or popular
          fundraising pages, campaigns or events.<br /><br />

          4. providing users with information on how others are using our
          website, including: (i) their friends (or people they are connected
          with) on social networks; and/or (ii) other people which we have
          noticed the user is connected with via service usage (this uses our
          functionality which profiles factors including the user's connections,
          trends and donation history). For example if you and one of your
          Facebook friends have logged in through Facebook and are both using
          our site we might let your friend know that you are donating via our
          site. We will only do this where we have appropriate permissions or
          you have provided appropriate permissions to the social network as are
          required by privacy and data protection laws;<br /><br />

          5. to help us prevent fraud associated with online payments.<br /><br />

          6. providing information to charities to let them know about
          contributions made to their fundraising page; <br /><br />

          7. allowing charities and not-for-profits to have visibility of the
          donations made to them so they can track the total donations (in an
          anonymous form, unless the donor provides appropriate permissions).<br /><br />

          8. where the donor provides appropriate permissions, to allow
          charities and not-for-profits to: (i) provide that donor with more
          details about their activities; and/or (ii) contact individual donors
          to pass on a thank you message;<br /><br />

          9. if we or substantially all of our assets are acquired by a third
          party, in which case personal data held by us about our customers will
          be one of the transferred assets;<br /><br />

          10. providing service update emails regarding the ZAKchat service and
          your specific account; and<br /><br />

          11. improving the service we offer - for example you may be asked to
          complete one of our online user satisfaction surveys.<br /><br />

          We understand that many donors like to make donations anonymously. We
          respect this and will not publicly display your name on donations you
          have made on our site unless we have your permission to do so.
          However, the creator of a fundraising page you donate to will be
          provided with your name (but not your email address, unless you have
          provided your permission for us to do so). We require all fundraising
          page creators to keep this information safe and secure.<br /><br />

          We do not provide any personal information to a charity or
          not-for-profit relating to your donation unless we have your
          permission to do so (or where we are required to do so by applicable
          law).<br /><br />

          If you allow us to pass on your details to a charity or a
          not-for-profit, you may receive further correspondence from them
          including emails telling you more about their cause and to thank you
          for your donation. If you do not allow us to pass on your details to
          them for these purposes you will be anonymous to them (except for a
          fundraising page creator, as above) and they will not be able to
          contact you in this way. Any further correspondence you receive from
          them will not be governed by this Policy; it will be governed by their
          privacy policies (if applicable) and you may ask them to stop
          communicating with you by contacting them in accordance with the terms
          of their privacy policy (if applicable) and the relevant laws.<br /><br />

          We will only use your information where we have obtained appropriate
          permissions from you (e.g. with tick boxes) and will only use the
          information in accordance with this Policy, or where it is required
          and authorised by law.
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Providing information to others
        </div>
        <div class="zch-privacy-regulations-item-text">
          We may disclose your data to our affiliated organisations and
          subsidiaries, and to service providers who render services to us or
          you on our behalf. We also may disclose your information if required
          by law, requested by law enforcement authorities or to enforce our
          legal rights, such as pursuant to a subpoena or to HMRC when you claim
          Gift Aid. We may share your information in connection with a sale or
          reorganisation of ZAKchat, but in any such case, the terms of this
          Policy will continue to apply. Our service providers include:
          <ul>
            <li>
              banks and payment providers - to authorise and complete payment
              transactions;
            </li>
            <li>
              organisations within the payment card industry to help prevent
              online fraud;
            </li>
            <li>
              law enforcement bodies in order to comply with any legal
              obligation or court order and, where we are otherwise requested to
              provide information, we may provide it but only in accordance with
              applicable privacy and data protection laws;
            </li>
            <li>
              HM Revenue & Customs, but only if we need to authorise claims for
              Gift Aid or other forms of tax efficient ways of giving; and
            </li>
            <li>Charities and not-for-profits, but only as set out above.</li>
            <li>
              We may also provide information to third party service providers
              who process information on our behalf to help run some of our
              internal business operations including email distribution, IT
              services and customer services.
            </li>
            <li>
              We will only share information with such organisations where we
              have your permission to do so in accordance with this Policy, or
              where we believe it is necessary for a legitimate reason connected
              with the services we offer.
            </li>
            <li>
              You agree that we may disclose or share your personal information
              with such partners in order that we may operate the ZAKchat
              service.
            </li>
          </ul>
          We may also provide information to third party service providers who
          process information on our behalf to help run some of our internal
          business operations including email distribution, IT services and
          customer services.<br /><br />
          We will only share information with such organisations where we have
          your permission to do so in accordance with this Policy, or where we
          believe it is necessary for a legitimate reason connected with the
          services we offer.<br /><br />
          You agree that we may disclose or share your personal information with
          such partners in order that we may operate the ZAKchat service.
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Working with other service providers and our group companies
        </div>
        <div class="zch-privacy-regulations-item-text">
          For operational reasons, we transfer personal information to service
          providers and our group companies who help us manage our systems and
          processes (such as card payment providers), and to deliver our
          services to users.<br /><br />
          These service providers and group companies may be located in the UK,
          other countries in the European Economic Area or elsewhere in the
          world. Different privacy laws may apply in these countries and you
          understand and unambiguously consent to the transfer of personal
          information to these countries, group companies and service
          providers.<br /><br />
          We only make these arrangements or transfers where we are satisfied
          that adequate levels of protection are in place to protect any
          information held in that country or that the service provider acts at
          all times in compliance with applicable privacy laws. Where required
          under applicable laws we will take measures to ensure that personal
          information handled in other countries will receive at least the same
          level of protection as it is given in your home country.<br /><br />
          In the event your personal information is transferred to a foreign
          jurisdiction, it may be subject to the laws of that jurisdiction and
          we may be required to disclose it to the courts, law enforcement or
          governmental authorities in those jurisdictions but we will only do so
          where required by applicable laws.
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Keeping information secure
        </div>
        <div class="zch-privacy-regulations-item-text">
          We invest significant resources to protect your personal information,
          from loss, misuse, unauthorised access, modification or disclosure.
          However, no internet-based site can be 100% secure and we cannot be
          held responsible for unauthorised or unintended access that is beyond
          our control
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Keeping your records
        </div>
        <div class="zch-privacy-regulations-item-text">
          We keep records for as long as required to operate the service in
          accordance with legal, tax and accounting requirements. Where your
          information is no longer required, we will ensure it is disposed of in
          a secure manner and, where required by applicable law (e.g. in the
          UAE) we will notify you when such information has been disposed of.
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Analytics and social media plugins
        </div>
        <div class="zch-privacy-regulations-item-text">
          We may use analytics tools on our website to provide the service you
          request, identify service issues to us, improve our services and to
          provide content tailored to users' personal preferences and
          profiles.<br /><br />

          These tools may be provided by third-party service providers and may
          include the collection and tracking of certain data and information
          regarding the characteristics and activities of visitors to our
          website. We may disclose data, including personal information, to
          certain such third-party services providers in order to obtain such
          services.<br /><br />

          If you use buttons on our website linked to social media or similar
          sites (for example "Like" and/or "Share" buttons), content from our
          website may be sent back to that other website and, depending on your
          privacy settings, may be privately or publicly visible (for example to
          friends, followers or generally to anyone who has access to your
          profile page).<br /><br />

          You may request further details on these other tools and third parties
          from us.
        </div>
        <div class="zch-privacy-regulations-item-heading">Cookies</div>
        <div class="zch-privacy-regulations-item-text">
          Our website uses cookies (which includes third party cookies to
          support social media, personalisation and analytics functionality).<br /><br />

          Cookies are small computer files which are downloaded onto your device
          and collect information about the way in which you navigate and use
          our website and the internet. Cookies do not collect information which
          allows us to identify individual users. The information provided by
          cookies helps us to provide you with a more personal experience and
          allows us to make improvements to our services over time. You may
          delete and block all cookies or decide to just block certain types of
          Cookie via your browser setting. However, if you choose to block or
          delete Cookies, this may affect the functionality of the website.
        </div>
        <div class="zch-privacy-regulations-item-heading">Access rights</div>
        <div class="zch-privacy-regulations-item-text">
          You have the right to request copies of certain of your personal
          information within our custody and control and details of how we use
          that information. If you think any of the personal information we hold
          about you is inaccurate, you may also request it is corrected. You
          also have a right, in certain circumstances, to require us to stop
          processing your personal information.<br /><br />

          In relation to all of these rights, please email or write to us at the
          address below. Please note that we may, where permitted under
          applicable law, charge a small administrative fee and / or request
          proof of identity. We will respond to your requests within all
          applicable timeframes.<br /><br />

          In certain circumstances (for example where required or permitted by
          law) we might not be able to provide you with access to some of your
          personal information, but where appropriate we will notify you of the
          reasons for this
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Complaints Process
        </div>
        <div class="zch-privacy-regulations-item-text">
          If you have a complaint about how we have handled your personal
          information you may contact us using the details below and we will
          investigate your complaint.
        </div>
        <div class="zch-privacy-regulations-item-heading">
          Third party sites
        </div>
        <div class="zch-privacy-regulations-item-text">
          This Policy only applies to the ZAKchat.com site. If you land on our
          site from other websites (or move to other sites from our website) you
          should read their separate privacy policies.
        </div>
        <div class="zch-privacy-regulations-item-heading">Updates</div>
        <div class="zch-privacy-regulations-item-text">
          We keep this Policy under regular review and place updates on
          ZAKchat.com from time to time. Please review this policy periodically
          for changes.<br /><br />

          However, we will always notify you of material changes we make to this
          Policy and, where required under applicable privacy and data
          protection legislation, we will appropriately notify or give you the
          option to consent to changes to the Policy.<br /><br />

          If you do not accept the amended Policy, please stop using our
          website.
        </div>
        <div class="zch-privacy-regulations-item-heading">Contact</div>
        <div class="zch-privacy-regulations-item-text">
          If you have any questions about this Policy, or would like to exercise
          your rights with respect to your personal information, please contact
          us via contact@ZAKchat.com or write to ZAKchat, 71-75, Shelton Street,
          Covent Garden, London, WC2H 9JQ.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../layouts/Navigation";

export default {
  name: "PrivacyPolicy",
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
.zch-privacy {
  min-height: 100vh;
  width: 100%;
  padding: 24px;
  background: url(../assets/background-logo.png) no-repeat,
    radial-gradient(circle at 50% 50%, #fbfffe, #f6fffc 151%) no-repeat;
  background-size: contain;
  background-position-x: right;
  margin-bottom: 50px;

  &-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 16.75%;
  }

  &-heading {
    margin-top: 50px;
    font-family: Muli, sans-serif;
    text-align: center;
    margin-bottom: 91px;

    & > img {
      max-height: 70px;
    }

    &-effective-date {
      font-family: Muli, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: $color-montecarlo;
    }

    &-notice {
      font-size: 44px;
      font-weight: 800;
      text-align: center;
      color: #2b2b2b;
    }
  }

  &-regulations {
    &-item {
      &-heading {
        font-family: Muli, sans-serif;
        font-size: 20px;
        font-weight: 800;
        color: #2b2b2b;
        margin-left: -33px;
        padding-top: 40px;
      }

      &-text {
        padding-top: 13px;
        font-family: Muli, sans-serif;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.94;
        letter-spacing: normal;
        color: #6c6c6c;
      }
    }
  }
}
</style>
